<template>
  <section id="dashboard-analytics">
    <h6 class="mb-2">Liste des inspections attribuées</h6>

    <b-row class="match-height" style="width:100%">
      <b-col lg="12">

          <b-table
              ref="refUserListTable"
              class="position-relative"
              :items="inspections"
              responsive
              :fields="fieldsInspection"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
            >

              <template #cell(dossier)="data">
                <div class="text-nowrap">
                  <b-link color="text-primary">Télécharger le dossier d'inspection</b-link>
                </div>                                  
              </template>
              <template #cell(type)="data">
                <div class="text-nowrap">
                  <b-link  @click="goToInspect(data.item.id)">{{ data.item.type }}</b-link>
                </div>
              </template>           
              <template #cell(ouvragelst)="data">
                <div class="text-nowrap">
                  <b-link  @click="goToInspect(data.item.id)">{{ data.item.ouvragelst }}</b-link>
                </div>
              </template>    
              <template #cell(ouvrage)="data">
                <div class="text-nowrap">
                  <span class="align-text-top text-capitalize">{{ data.item.ouvragelst }}</span>
                </div>
              </template>

              <!-- Column: Status -->
              <template #cell(date)="data">
                <span class="align-text-top text-capitalize">     
                  {{ data.item.date }}
                </span>
              </template>

              <!-- Column: Status -->
              <template #cell(status)="data">
                <b-badge
                  pill
                  :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                  class="text-capitalize"
                >
                  {{ data.item.status }}
                </b-badge>
              </template>                               
          </b-table>

      </b-col>
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol, BTable, BLink, BBadge } from 'bootstrap-vue'
import inspectionmodal from '../../apps/inspection/InspectionModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BLink,
    BBadge,
    inspectionmodal,
  },
  data() {
    return {
      inspections: [],
      fieldsInspection: [
        { key: 'type',label:"Type d'inspection", sortable: true },
        { key: 'ouvragelst',label:"Liste d'ouvrage", sortable: true },
        { key: 'startdate',label:"Date", sortable: true },
        { key: 'status',label:"Statut", sortable: true },
        { key: "dossier", label: "", type: "text", thStyle: { width: "16%" }},
      ],
    }
  },
  setup() {
       const resolveUserStatusVariant = status => {
        if (status === 'retard') return 'warning'
        if (status === 'terminé') return 'success'
        if (status === 'inactive') return 'secondary'
        return 'primary'
      }
      return {
        resolveUserStatusVariant
      }
  },
  created() {

    this.$http.get('/apps/inspection/inspections',{ params: {q:{assets:[],type:[],queries:[], status:['planifié','en cours','rapport']}} }) 
      .then(response => {
        this.inspections = response.data.inspections
        this.inspections.sort(this.sortOuvragelist)
      })      
  },
  methods: {
    sortOuvragelist: function(a, b) {
      if (a.id < b.id) 
        return -1
      if (a.id > b.id)
        return 1
      return 0
    },

    goToInspect(id) {
      this.$router.push({ path: '/apps/inspection/inspect/'+id })
    },

  },  
}
</script>
